<template>
  <div class="col-md-3 sideBar">
    <div class="holiday-rht">
      <h2>{{ $t("product-page.location") }}</h2>
      <h3>
        <i class="fas fa-map-marker-alt"></i>
        <span>{{ destination }}</span>
      </h3>
      <div class="map-hldr">
        <Map
          :width="'600'"
          :height="'450'"
          :address="destination + ' ' + hotelName"
        />
      </div>
      <div class="remarks" v-if="remarks" >
        <h2>{{ $t("product-page.remarks") }}</h2>
        <p v-html="remarks"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Map: () => import('@/components/productPage/atoms/GoogleMap'),
  },
  props: {
    hotelName: {
      type: String,
      default: '',
    },
    destination: {
      type: String,
      default: '',
    },
    remarks: {
      type: String,
      default: '',
    },
  },
  created() {},
  methods: {
    splitParagraph(str) {
      return str.split(':').join(':<br>').split('.').join('.<br>');
    },
  },
};
</script>
